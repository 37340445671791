import React, { useRef, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import {
  Label,
  ButtonContainer,
  LabelValueContainer,
  Value,
  ProfilePic,
  ProfilePicContainer,
} from "../styles";
import FormField from "../../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import useUser from "../../../../hooks/useUser";
import useLoader from "../../../../hooks/useLoader";
import AWSService from "../../../../services/AWSService";
import { FieldArray, Formik } from "formik";
import { EditProfileSchema } from "../../../../helpers/validationSchema";
import AuthService from "../../../../services/AuthService";
import { useSnackbar } from "notistack";
import { dataURLtoFile, getErrorMessage } from "../../../../helpers/functions";
import labels from "../../../../helpers/labels.json";
import { Fragment } from "react";
import { FormLabel } from "@material-ui/core";
import { CustomInputField } from "../../../common/FormInputs";
import { AddButton } from "../../../internal/CreateProfile/CreateProfile";
import ImageCropper from "../../../common/ImageCropper";

const inputs = [
  {
    name: "tagLine",
    label: labels["onboarding.tagLine"],
    type: "text",
    required: true,
  },
  {
    name: "qualification",
    label: labels["onboarding.qualification"],
    type: "text",
    required: true,
  },
  {
    name: "experience",
    label: labels["onboarding.yearsOfExperience"],
    type: "text",
    required: true,
  },
  {
    name: "languagesKnown",
    label: labels["onboarding.language"],
    type: "text",
    required: true,
  },
  {
    name: "location",
    label: labels["onboarding.location"],
    type: "text",
    required: true,
  },
  {
    name: "affiliatedOrg",
    label: labels["onboarding.affiliatedOrg"],
    type: "text",
    required: true,
  },
  {
    name: "alternateMobileNumber",
    label: labels["onboarding.alternateMobileNumber"],
    type: "text",
    required: false,
  },
  {
    name: "institutionOfMediationTraining",
    label: labels["onboarding.institutionOfMediationTraining"],
    type: "text",
    required: false,
  },
  {
    name: "numberOfMediationsConducted",
    label: labels["onboarding.numberOfMediationsConducted"],
    type: "text",
    required: false,
  },
];

export default function ProfileModal({ modal, setModal }) {
  const { userObject } = useUser();
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [preCropImage, setPreCropImage] = useState("");
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [fileType, setFileType] = useState("");

  const cropImageData = (file) => {
    if (file.type.includes("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreCropImage(reader.result);
      };
      setOpenCropperModal(!openCropperModal);
      setFileType(file.type);
      reader.readAsDataURL(file);
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  };

  const uploadCroppedDate = (cropData, setFieldValue, setFieldError) => {
    const file = dataURLtoFile(cropData, fileType);
    s3Upload(file, "avatarUrl", setFieldValue, setFieldError);
  };

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file.type.includes("image/png", "image/jpeg", "image/jpg")) {
      try {
        setLoader({ state: true, message: `Uploading file: ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        setFieldError(field, "Could not upload this file");
      } finally {
        setLoader({ state: false });
        setOpenCropperModal(!openCropperModal);
      }
    } else {
      return enqueueSnackbar("Invalid File Type", {
        variant: "error",
      });
    }
  }

  async function onFormSubmit(values) {
    const postData = JSON.parse(JSON.stringify(values));
    try {
      setLoader({ state: true, message: "Updating profile..." });
      const response = await AuthService.profileUpdate(postData);
      if (response.me) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        window.location.reload();
        setModal(false);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{labels["actions.edit_profile"]}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Formik
            initialValues={{
              avatarUrl: userObject?.avatarUrl || "",
              name: userObject?.name || "",
              tagLine: userObject?.tagLine || "",
              qualification: userObject?.qualification || "",
              experience: userObject?.experience || "",
              languagesKnown: userObject?.languagesKnown || " ",
              location: userObject?.location || "",
              affiliatedOrg: userObject?.affiliatedOrg || "",
              specialities: userObject?.specialities || [""],
              shortBio: userObject?.shortBio || "",
              alternateMobileNumber: userObject?.alternateMobileNumber || "",
              institutionOfMediationTraining:
                userObject?.institutionOfMediationTraining || "",
              numberOfMediationsConducted:
                userObject?.numberOfMediationsConducted || "",
              lastHeldPosition: userObject?.lastHeldPosition || "",
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={EditProfileSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
              setFieldTouched,
            }) => (
              <>
                <ProfilePicContainer>
                  <ImageWrapper onClick={() => fileRef.current.click()}>
                    <ProfilePic
                      src={
                        values?.avatarUrl
                          ? values.avatarUrl
                          : require("../../../../assets/images/userPlaceholder.svg")
                      }
                    ></ProfilePic>
                    <PickerIcon
                      src={require("../../../../assets/images/imagePicker.svg")}
                    />
                  </ImageWrapper>
                </ProfilePicContainer>
                <BasicDetails>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 20 }}
                  >
                    <Label>{labels["user.name"]}</Label>
                    <Value>{userObject?.name}</Value>
                  </LabelValueContainer>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 20 }}
                  >
                    <Label>{labels["user.email"]}</Label>
                    <Value>{userObject?.email}</Value>
                  </LabelValueContainer>
                  <LabelValueContainer
                    style={{ width: "auto", marginBottom: 20 }}
                  >
                    <Label>{labels["user.mobile"]}</Label>
                    <Value>{userObject?.mobile}</Value>
                  </LabelValueContainer>
                </BasicDetails>
                <Flex style={{ flexWrap: "wrap" }}>
                  {inputs?.map((input, index) => (
                    <HalfWidth key={index}>
                      <FormField
                        {...{
                          touched,
                          errors,
                          input,
                          values,
                          handleChange,
                          handleBlur,
                        }}
                      />
                    </HalfWidth>
                  ))}
                  <FormField
                    input={{
                      type: "text",
                      name: "lastHeldPosition",
                      label: labels["onboard.lastHeldPosition"],
                      required: true,
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </Flex>
                <div>
                  <FieldArray
                    name="specialities"
                    render={(arrayHelpers) => (
                      <div
                        style={{
                          marginTop: 6,
                        }}
                      >
                        {values?.specialities?.length &&
                          values?.specialities.map((speciality, index) => (
                            <Fragment key={index}>
                              <FormLabel
                                style={{
                                  fontFamily: theme.fonts.primaryFontSemiBold,
                                  fontSize: 12,
                                  color: COLORS.INPUT_LABEL,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ display: "flex", flex: 1 }}>
                                  {labels["onboarding.specialities"]}
                                  <span className="required-star"> *</span>
                                </div>
                                {values?.specialities?.length > 1 ? (
                                  <div>
                                    <img
                                      onClick={() => arrayHelpers.remove(index)}
                                      alt="delete"
                                      style={{
                                        height: 16,
                                        width: 16,
                                        cursor: "pointer",
                                      }}
                                      src={require("../../../../assets/images/removeIcon.svg")}
                                    />
                                  </div>
                                ) : null}
                              </FormLabel>
                              <CustomInputField
                                type={"text"}
                                value={speciality}
                                error={
                                  errors.specialities?.length &&
                                  touched.specialities?.length &&
                                  errors?.specialities[index] &&
                                  touched?.specialities[index]
                                }
                                helperText={
                                  errors.specialities?.length &&
                                  touched.specialities?.length &&
                                  touched?.specialities[index] &&
                                  errors?.specialities[index]
                                }
                                id={`specialities[${index}]`}
                                name={`specialities[${index}]`}
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  marginTop: 6,
                                  marginBottom: 20,
                                }}
                              />
                            </Fragment>
                          ))}
                        <AddButton
                          type="button"
                          onClick={() => {
                            const index = values?.specialities?.findIndex(
                              (el) => !el.trim(),
                            );
                            if (index !== -1) {
                              setFieldTouched(`specialities[${index}]`, true);
                            } else {
                              arrayHelpers.push("");
                            }
                          }}
                        >
                          + Add another specialities
                        </AddButton>
                      </div>
                    )}
                  />
                </div>

                <div style={{ marginTop: 30 }}>
                  <FormField
                    maxLength={0}
                    input={{
                      type: "textarea",
                      name: "shortBio",
                      label: labels["onboarding.shortbio"],
                    }}
                    {...{
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                </div>

                <ButtonContainer>
                  <PrimaryOutlinedCTAButton
                    onClick={() => setModal(false)}
                    style={{ width: "47%" }}
                  >
                    {labels["buttons.back"]}
                  </PrimaryOutlinedCTAButton>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "47%" }}
                  >
                    {labels["buttons.save"]}
                  </PrimaryCTAButton>
                </ButtonContainer>
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) =>
                    cropImageData(
                      e.target.files[0],
                      "avatarUrl",
                      setFieldValue,
                      setFieldError,
                    )
                  }
                />
                <ImageCropper
                  {...{
                    openCropperModal,
                    setOpenCropperModal,
                    preCropImage,
                    setPreCropImage,
                  }}
                  onCropped={(data) =>
                    uploadCroppedDate(data, setFieldValue, setFieldError)
                  }
                />
              </>
            )}
          </Formik>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 618px;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const PickerIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 34px;
  height: 34px;
  object-fit: contain;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  margin-bottom: 2px;
  cursor: pointer;
  user-select: none;
`;

const BasicDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 425px;
  margin-bottom: 20px;
`;
