import React, { useState, useRef } from "react";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import styled, { css } from "styled-components";
import { Add } from "@material-ui/icons";
import DocumentUploadModal from "../../../common/MultipleDocumentUploadModal";
import { getErrorMessage } from "../../../../helpers/functions";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import CaseService from "../../../../services/CaseService";
import AWSService from "../../../../services/AWSService";
import AlertDialog from "../../../common/Alert";

const validFormat = [
  "application/pdf", // .pdf
  "image/png", // .png
  "image/jpeg", // .jpeg, .jpg
  "image/tiff",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "video/mp4",
  "audio/mpeg",
  "image/svg",
];

export default function AddDocument({
  id,
  caseDetails,
  isActionsDisabled,
  setTrigger,
}) {
  const [docUploadModal, setDocUploadModal] = useState(false);
  const fileRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [file] = useState([]);
  const [url, setUrl] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [data, setData] = useState([]);

  async function s3Upload(file) {
    if (file) {
      let len = file.length;
      let fileName = [];
      for (let i = 0; i < len; i++) {
        fileName.push(file[i]?.name);
      }
      const validFiles = [];
      let hasError = false;
      const selectedFiles = Array.from(file);
      // Validate files
      selectedFiles.forEach((file) => {
        if (!validFormat.includes(file.type)) {
          hasError = true;
        } else {
          validFiles.push(file?.name);
        }
      });
      try {
        const errorMessage = "Invalid File Type";
        if (hasError) throw errorMessage;
        setLoader({ state: true, message: `Uploading file...` });
        const payload = {
          key: validFiles,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const s3url = [];
          for (let i = 0; i < len; i++) {
            const res = await AWSService.uploadToS3(
              s3Response?.url[i],
              file[i],
            );
            s3url.push(res);
          }
          setUrl(s3url);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        hasError = false;
        setLoader({ state: false });
      }
    }
  }

  /** Function to get the negotiation round Details */
  async function addDocumentForCase(data) {
    try {
      setLoader({ state: true, message: `Uploading document` });
      const documentDetails = data;
      const upload_response = await CaseService.caseAddDocument(
        { documentDetails },
        id,
      );
      enqueueSnackbar("Successfully Uploaded", { variant: "success" });
      if (upload_response) {
        setTrigger(true);
      }
      // setUrl("");
      setData([]);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpenAlert(false);
      setDocUploadModal(false);
      setAlertData({});
    }
  }

  return (
    <Card disabled={isActionsDisabled}>
      <div
        className="add_circle"
        onClick={() => !isActionsDisabled && setDocUploadModal(true)}
      >
        <Add className="add_icon" />
      </div>
      {docUploadModal && (
        <DocumentUploadModal
          menuItems={Object.entries(
            caseDetails?.documentCategoryandTypes,
          ).flatMap(([category, names]) =>
            names.map((name) => ({ category, name })),
          )}
          modal={docUploadModal}
          setModal={setDocUploadModal}
          handleUpload={s3Upload}
          file={file}
          fileRef={fileRef}
          url={url}
          setUrl={setUrl}
          data={data}
          setData={setData}
          resolutionKind={caseDetails?.resolutionKind}
          shareDefault={caseDetails?.resolutionKind}
          handleSuccess={(data) => {
            setOpenAlert(true);
            setAlertData({
              clickSecondarybtn: () => setOpenAlert(false),
              onDialogClose: () => setOpenAlert(false),
              primaryBtnText: "Ok",
              secondaryBtnText: "Cancel",
              desc:
                data && data.length === 1
                  ? `${
                      data[0]["visibilityType"] === "all"
                        ? "Are you sure you want to share this document with all parties and Case Manager?"
                        : data[0]["visibilityType"] === "me"
                        ? "Are you sure you want to keep this document private?"
                        : "Are you sure you want to share this document with Case Manager?"
                    }`
                  : "Are you sure you want to share this document?",
              // desc: `${
              //   checked && !checkedme
              //     ? "Are you sure you want to share this document with Case Manager?"
              //     : !checked && !checkedme
              //     ? "Are you sure you want to keep this document private?"
              //     : "Are you sure you want to share this document with all parties and Case Manager?"
              // }`,
              heading: `Alert`,
              clickPrimaryBtn: () => addDocumentForCase(data),
            });
          }}
          hasDocumentType={caseDetails?.resolutionKind === "arbitration"}
        />
      )}
      <AlertDialog isOpen={openAlert} {...{ ...alertData }} />
    </Card>
  );
}

export const Card = styled.div`
  display: flex;
  border-radius: 7px;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
  & .add_case_casemanager {
    color: ${COLORS.INPUT_LABEL};
    font-family: ${theme.fonts.primaryFontSemiBold};
  }

  & .add_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    cursor: pointer;
    background-color: rgba(0, 131, 140, 0.13);
  }
  & .add_icon {
    color: ${COLORS.BTN_GREEN};
    font-size: 18px;
  }
`;
